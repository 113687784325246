/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'

// Interface
import { ServicesProps } from 'components/flex/Services/Shell'

const Gradient = styled.div`
  background: ${({ theme }) => theme.color.secondary};
  padding-bottom: 6rem;

  & svg {
    opacity: 0.5;
    @media (max-width: 575px) {
      height: 40px;
    }
  }
`

const Content = styled(ParseContent)`
  flex: none;
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    line-height: 45px;
    @media (min-width: 992px) {
      font-size: 40px;
    }
  }
`

const ServicesWrapper = styled.div`
  margin-top: -6rem;
`

const Service = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.2s ease-in-out;
  will-change: transform;

  & span {
    color: ${({ theme }) => theme.color.dark};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 16px;
  }

  &:hover {
    filter: drop-shadow(5px 5px 5px #ffffff);
  }
`

const Icon = styled(Plaatjie)`
  width: 90px;
  height: 90px;
  @media (max-width: 575px) {
    width: 50px;
    height: 50px;
  }
`

const Services: React.FC<ServicesProps> = ({ fields }) => (
  <section className="mb-5 pb-lg-5">
    <Gradient>
      <div className="container py-5">
        <div className="d-flex align-items-end justify-content-between">
          <Content content={fields.description} className="d-flex me-4" />
        </div>
      </div>
    </Gradient>
    <div className="container">
      <ServicesWrapper className="row">
        {fields.services?.map((service, index) => (
          <AnimatePresence>
            <motion.div
              initial={{
                x: '100%',
                filter: 'drop-shadow(0px 4px 20px rgba(255, 255, 255, 0.3))',
              }}
              animate={{
                x: [100 * index + 100, 0],
                filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.3))',
              }}
              className="col mb-4 mb-lg-0"
              key={index}
            >
              <NavLink to={service?.link?.url || '#'} className="h-100">
                <Service className="d-flex flex-column align-items-center justify-content-between p-3 p-sm-4 h-100">
                  <Icon image={service?.icon} alt="" />
                  <span className="d-flex align-items-center text-center mt-sm-4">
                    {service?.link?.title}
                  </span>
                </Service>
              </NavLink>
            </motion.div>
          </AnimatePresence>
        ))}
      </ServicesWrapper>
    </div>
  </section>
)

export default Services
